.App {
  text-align: center;
}

@font-face {
  font-family: Roboto;
  src: url(../public/fonts/Roboto/Roboto-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}


@font-face {
  font-family: Roboto-reg;
  src: url(../public/fonts/Roboto/Roboto-Regular.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Roboto-mono;
  src: url(../public/fonts/Roboto_Mono/static/RobotoMono-Regular.ttf);
  font-style: normal;
  font-weight: 700;
}

body {
  background-image: url(../public/images/background.jpg);
  background-size: cover;
  margin: 0;
  font-family: Roboto;
  min-height: 100vh;
  max-height: 100vh;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  }



.logo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
}


.slogan{
  color: rgba(12, 62, 138, 1);
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 72px;
  font-weight: 700;
}

.site{
  font-family: Roboto-reg;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0em;
  color: rgba(12, 62, 138, 1);
  text-align: center;
  margin-bottom: 5vh;
}

.timer-conteiner{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Roboto-mono;
  color: white;
  font-size: 15px;

}



.block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.block p{
  color:rgba(12, 62, 138, 1);
  background-color: white;
  text-align: center;
  margin-right: 1vw;
  width: 8vw;
  font-size: 45px;
  margin-bottom: 1vw;
  border-radius: 8%;
}



.icon{
  text-align: right;
  margin-right: 5vw;
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.button{
  margin-right: 1vw;
}

@media (max-width: 1980px) {
  .content {
      max-width: 1581px;
      margin: 0, 165px;
  }
}

@media (max-width: 1558px) {
  .content {
      max-width: 1130px;
      margin: 0, 150px;
  }

  p.slogan{
    margin-top: 15vh;
      font-size: 90px;
  }

 
}

@media (max-width: 1200px) {
  body{
    background-size: cover;
  } 

  .logo{
    margin-top: 10vh;
  }

  p.slogan{
      font-size: 60px;
      margin-top: 18vh;
  }
  .site{
      font-size: 30px;
  }
  
  .icon{
      margin-top: 5vh;
  }
}

@media (max-width: 992px) {
  body{
    background-size: cover;
  } 

.logo{
  margin-top: 10vh;
}

  .slogan{
    margin-top: 20vh;
  }

.site{
  margin-bottom: 6vh;
}

  .icon{
      text-align: right;
      margin-top: 5vh;
      margin-right: 5vh;
  }

}

@media (max-width: 768px) {
  body{
    background-size: cover;
  } 

  .logo{
      width: 134px;
      margin-top: 10vh;
  }

  p.slogan{
    margin-top: 15vh;
      font-size: 40px;
  }

  .site{
    margin-bottom: 5vh;
  }
  
  .block p{
    font-size: 26px;
  }

  .icon{
    margin-top: 10vh;
      text-align: right;
  }

}

@media (max-width: 640px) {
  body{
    background-size: auto;
  } 

  .logo{
      width: 134px;
  }

  p.slogan{
    margin-top: 20vw;
      font-size: 40px;
  }

  .site{
    margin-bottom: 5vw;
  }
  
  .block p{
    width: 9vw;
    font-size: 26px;
  }

  .text{
    padding: 0 25%;
    font-size: 9px;
  }

  .icon{
    margin-top: 20vw;
      text-align: right;
  }

}


@media (max-width: 450px) {

  body{
    background-size: auto;
  } 

  .logo{
    width: 134px;
    margin-top: 20vw;
  }

  p.slogan{
    margin-top: 40vw;
      font-size: 43px;
  }

  .site{
    margin-bottom: 15vw;
    font-size: 25px;
  }
  
  .block p{
    width: 15vw;
    font-size: 30px;
  }

  .icon{
    margin-top: 25vw;
    text-align: right;
    margin-bottom: 5vw;
  }

}